"use client";
import React, { useRef, useState } from "react";



import { Button, Typography, Input, Textarea } from "@material-tailwind/react";
import toast from "react-hot-toast";
import {gql, useMutation} from "@apollo/client";

export var gql_ContactUs = gql`
  mutation contact(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $emailAddress: String
    $message: String
    $location: String
  ) {
    contactUs(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      message: $message
      location: $location
    )
  }
`;

const Contact = () => {
  const [apiContactUs, { loading: ContactUsLoading }] =
    useMutation(gql_ContactUs);
  const [Submitted, SetSubmitted] = useState(false);
  const contactData = useRef({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    message: "",
    location: "",
  });

  function submit() {




    apiContactUs({ variables: contactData.current })
      .then((result) => {
        if (result?.data?.contactUs) {
          SetSubmitted(true);
            toast.success("Your message has been sent!", {
                position: "top-right",
            });
        } else {
          toast.error("We are sorry, something went wrong. Please try again.", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
          toast.error("We are sorry, something went wrong with contacting our API. Please try again later.", {
              position: "top-right",
          });
      });
  }

  return (
    <div className={"container"}>
      <section className="grid  place-items-center">
        <div className={Submitted ? "text-center" : "hidden"}>
          <Typography
            variant="h1"
            color="blue-gray"
            className="mb-4 text-4xl lg:text-5xl"
          >
            Contact us
          </Typography>
          <Typography variant="lead" className="mb-16 !text-gray-500">
            For further questions, including partnership opportunities, please
            fill out the forum below. We do not reuse or resell your contact
            information.
          </Typography>

          <Typography variant="lead" className="mb-16 !text-gray-500">
            Thank you for submitting your message! We will respond soon!
          </Typography>
        </div>

        <div className={Submitted ? "hidden" : "text-center"}>
          <Typography
            variant="h1"
            color="blue-gray"
            className="mb-4 text-4xl lg:text-5xl"
          >
            Contact us
          </Typography>
          <Typography variant="lead" className="mb-16 !text-gray-500">
            For further questions, including partnership opportunities, please
            fill out the forum below. We do not reuse or resell your contact
            information.
          </Typography>

          <form action="#" className="flex flex-col gap-4">
            <div className="grid gap-4 lg:grid-cols-2">


              <Input
                  id={"first-name"}
                  color="gray"
                  size="lg"
                  label="First Name"
                  name="first-name"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  onChange={(e) =>
                      (contactData.current.firstName = e.target.value)
                  }
              />

              <Input
                  id={"last-name"}
                  color="gray"
                  size="lg"
                  label="Last Name"
                  name="last-name"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  onChange={(e) =>
                      (contactData.current.lastName = e.target.value)
                  }
              />

            </div>
            <div className="grid gap-4 lg:grid-cols-2">

              <Input
                  id={"email"}
                  color="gray"
                  size="lg"
                  label="Email Address"
                  name="email"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  onChange={(e) =>
                      (contactData.current.emailAddress = e.target.value)
                  }
              />



              <Input
                id={"phone-number"}
                color="gray"
                size="lg"
                label="Phone Number"
                name="phone-number"
                containerProps={{
                  className: "!min-w-full",
                }}
                onChange={(e) =>
                  (contactData.current.phoneNumber = e.target.value)
                }
              />
            </div>

            <div className={"hidden"}>
              <label htmlFor="message">Location</label>
              <input
                type="email"
                id="email-contact"
                className="form-control"
                onChange={(e) =>
                  (contactData.current.location = e.target.value)
                }
              />
            </div>

            <div className="h-max">
              <Textarea
                  
                  id={"message"}
                  color="gray"
                  size="lg"
                  label="What can we help you with"
                  name="message"
                  onChange={(e) => (contactData.current.message = e.target.value)}


                  containerProps={{
                    className: "!min-w-full",
                  }}


              />

            </div>

            <Button
              size="lg"
              color="gray"
              className="mt-6"
              fullWidth
              onClick={() => submit()}
            >
              send message
            </Button>
          </form>
        </div>
      </section>

      {/*    {Submitted ?*/}
      {/*        <MDBCardBody>*/}
      {/*            <MDBTypography>Thank you for your submission. We will reach out shortly!</MDBTypography>*/}
      {/*        </MDBCardBody>*/}

      {/*        :*/}

      {/*        <MDBCardBody>*/}

      {/*            <form>*/}
      {/*                <MDBRow className="justify-content-center">*/}
      {/*                    <MDBCol md="4">*/}
      {/*                        <div className="form-group">*/}
      {/*                            <label htmlFor="first-name">First Name</label>*/}
      {/*                            <input type="text" id="first-name" className="form-control form-control-md"*/}
      {/*                                   onChange={(e) => contactData.current.firstName = e.target.value}/>*/}
      {/*                        </div>*/}
      {/*                    </MDBCol>*/}
      {/*                    <MDBCol md="4">*/}
      {/*                        <div className="form-group">*/}
      {/*                            <label htmlFor="last-name">Last Name</label>*/}
      {/*                            <input type="text" id="last-name" className="form-control form-control-md"*/}
      {/*                                   onChange={(e) => contactData.current.lastName = e.target.value}/>*/}
      {/*                        </div>*/}
      {/*                    </MDBCol>*/}
      {/*                </MDBRow>*/}

      {/*                <MDBRow className="justify-content-center">*/}
      {/*                    <MDBCol md="8">*/}
      {/*                        <label htmlFor="phone-number" className="grey-text">Phone Number</label>*/}
      {/*                        <input type="tel" id="phone-number" className="form-control"*/}
      {/*                               onChange={(e) => contactData.current.phoneNumber = e.target.value}></input>*/}
      {/*                    </MDBCol>*/}
      {/*                </MDBRow>*/}

      {/*                <MDBRow className="justify-content-center">*/}
      {/*                    <MDBCol md="8">*/}
      {/*                        <label htmlFor="email-contact" className="grey-text">Email</label>*/}
      {/*                        <input type="email" id="email-contact" className="form-control"*/}
      {/*                               onChange={(e) => contactData.current.emailAddress = e.target.value}/>*/}
      {/*                    </MDBCol>*/}
      {/*                </MDBRow>*/}

      {/*                <MDBRow className="justify-content-center">*/}
      {/*                    <MDBCol md="8">*/}
      {/*                        <label htmlFor="message">Message</label>*/}
      {/*                        <textarea className="form-control" id="message" rows="5"*/}
      {/*                                  onChange={(e) => contactData.current.message = e.target.value}/>*/}
      {/*                    </MDBCol>*/}
      {/*                </MDBRow>*/}

      {/*                <MDBRow id="button" className={"pt-2"}>*/}
      {/*                    <MDBCol>*/}
      {/*                        <div className="text-center">*/}
      {/*                            <MDBBtn disabled={ContactUsLoading} type="button" data-mdb-ripple-color="dark"*/}
      {/*                                    onClick={() => submit()}>Send Your Message</MDBBtn>*/}
      {/*                        </div>*/}
      {/*                    </MDBCol>*/}
      {/*                </MDBRow>*/}
      {/*            </form>*/}
      {/*        </MDBCardBody>*/}

      {/*    }*/}

      {/*</MDBCard>*/}
    </div>
  );
};

export default Contact;
